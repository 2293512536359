import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import cpPlusWhite from "../../../../../assets/images/logo/Logo_Cleanproplus-white.png";

const CleanproCheck = ({ change, showButton, checkOperator }) => {

    const { t } = useTranslation();

    return (
        <div className="px-5">
            <div className="text-center mb-4">
                <img
                    src={cpPlusWhite}
                    alt="cleanpro plus logo"
                    className="img-fluid"
                />
                <p>{t("An easier way to pay")}</p>
            </div>

            <div>
                <button
                    id="register"
                    onClick={() => change("login")}
                    className="big-button form-control btn mb-2"
                >
                    {t("Login")}
                </button>
                <button
                    id="login"
                    onClick={() => change("register")}
                    className="big-button form-control btn mb-2"
                >
                    {t("Create account")}
                </button>

                {showButton ? (
                    <Link
                        id="skip-to-payment"
                        to="/user/payment"
                        className="big-button-alt form-control btn mb-2"
                        onClick={checkOperator}
                    >
                        {t("Pay as guest")}
                    </Link>
                ) : null}

                <p className="link-text">
                    {t("By signing up, you agree to")} Cleanpro Plus'{" "}
                    <Link id="policy" to="/termsConditions" className="white-t mb-2">
                        {t("Terms")} &amp; {t("Conditions")}{" "}
                    </Link>
                    {t("and")}{" "}
                    <Link id="terms" to="/privacyPolicy" className="white-t mb-2">
                        {t("Privacy Policy")}
                    </Link>
                </p>
            </div>
        </div>
    )
}

export default CleanproCheck;